import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_calendar = _resolveComponent("van-calendar");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_field, {
    modelValue: $data.showvalue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.showvalue = $event),
    "is-link": "",
    readonly: "",
    label: $props.label,
    placeholder: '选择' + $props.label,
    onClick: $options.onClick,
    rules: $props.rules
  }, null, 8, ["modelValue", "label", "placeholder", "onClick", "rules"]), _createVNode(_component_van_calendar, {
    show: $data.showPicker,
    "onUpdate:show": _cache[1] || (_cache[1] = $event => $data.showPicker = $event),
    "default-date": $data.defaultDate,
    type: $props.type,
    onConfirm: $options.onselect,
    "min-date": $data.minDate,
    "max-date": $data.maxDate
  }, null, 8, ["show", "default-date", "type", "onConfirm", "min-date", "max-date"])]);
}