import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'dateBox',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    // 素材数据
    value: {
      type: String,
      default() {
        return '';
      }
    },
    // 素材数据
    format: {
      type: String,
      default() {
        return 'yyyy-MM-DD HH:mm:ss';
      }
    },
    // 素材类型
    type: {
      type: String
    },
    label: {
      type: String
    },
    rules: {
      type: Array
    },
    // 素材限制数量，默认5个
    num: {
      type: Number,
      default() {
        return 5;
      }
    },
    // 宽度
    width: {
      type: Number,
      default() {
        return 150;
      }
    },
    // 宽度
    height: {
      type: Number,
      default() {
        return 150;
      }
    }
  },
  data() {
    return {
      minDate: new Date(2022, 1, 1),
      maxDate: new Date(2030, 12, 31),
      defaultDate: new Date(),
      showPicker: false,
      thisvalue: '',
      showvalue: '',
      // headers: {
      //   Authorization: this.$store.getters.getToken(),
      // },
      dialogVisible: false,
      url: '',
      listDialogVisible: false,
      materialgroupList: [],
      materialgroupObjId: '',
      materialgroupObj: {},
      materialgroupLoading: false,
      tableData: [],
      resultNumber: 0,
      page: {
        total: 0,
        // 总页数
        currentPage: 1,
        // 当前页数
        pageSize: 12,
        // 每页显示多少条
        ascs: [],
        // 升序字段
        descs: 'create_time' // 降序字段
      },

      tableLoading: false,
      groupId: null
      // urls: [],
    };
  },

  computed: {
    ...mapGetters(['baseApi'])
  },
  methods: {
    // 监听上传失败
    handleError(e, file, fileList) {
      const msg = JSON.parse(e.message);
      this.$notify({
        title: msg.message,
        type: 'error',
        duration: 2500
      });
    },
    handleProgress(event, file, fileList) {
      console.log(event);
      // let uploadProgress = file.percentage.toFixed(0)
      // this.uploadProgress = uploadProgress
    },

    onselect(val) {
      switch (this.type) {
        case 'single':
          this.thisvalue = moment(val).format(this.format);
          this.showvalue = moment(val).format(this.format);
          break;
        case 'range':
          this.thisvalue = [];
          this.thisvalue[0] = moment(val[0]).format('yyyy-MM-DD 00:00:00');
          this.thisvalue[1] = moment(val[1]).format('yyyy-MM-DD 23:59:59');
          this.showvalue = this.thisvalue[0] + '至' + this.thisvalue[1];
          break;
      }
      this.$data.showPicker = false;
      this.$emit('update:value', val);
      this.$emit('updatevalue', moment(val).format(this.format));
    },
    onChange(obj, val) {
      //  this.thisvalue = val;
      // this.$data.showPicker=false;
      // console.log(val);
      // this.$emit('update:value', response.link)
    },
    onCancel(val, index) {
      this.$data.showPicker = false;
      // this.$emit('update:value', response.link)
    },

    onClick() {
      console.log('val');
      this.defaultDate = new Date(this.thisvalue ? this.thisvalue : new Date());
      ;
      this.$data.showPicker = true;
    },
    addDate(date, days) {
      var date = new Date(date);
      date.setDate(date.getDate() + days);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var mm = "'" + month + "'";
      var dd = "'" + day + "'";
      if (mm.length == 3) {
        month = '0' + month;
      }
      if (dd.length == 3) {
        day = '0' + day;
      }
      var time = year + '-' + month + '-' + day;
      return new Date(year, month, day);
    }
  },
  mounted: function () {
    this.thisvalue = this.value;
    this.$data.minDate = this.addDate(new Date(), -700);
    this.$data.maxDate = this.addDate(new Date(), 700);
    this.defaultDate = this.value;
    console.log(this.$data.maxDate);
  },
  watch: {
    thisvalue: function (newValue, oldValue) {
      //每当str的值改变则发送事件update:childnum , 并且把值传过去
      console.log('update:value', newValue);
      // this.$emit('updatevalue', newValue);
      this.showvalue = newValue;
    },
    value: function (newValue, oldValue) {
      console.log('update:value', newValue);
      //每当str的值改变则发送事件update:childnum , 并且把值传过去
      this.thisvalue = newValue;
      this.showvalue = newValue;
    }
  }
};